import LandingScreen from "./Components/LandingScreen/LandingScreen";
import { Switch, Route } from "react-router-dom";
import TermsAndConditions from "./Components/Legal/TermsAndConditions";
import PrivacyPolicy from "./Components/Legal/PrivacyPolicy";
import Navbar from "./Components/Navbar/Navbar";

function App() {
  return (
    <div
      className="hide-scroll-bar"
      style={{ height: "100vh", overflowX: "hidden", overflowY: "scroll" }}
    >
      <Navbar />
      <Switch>
        <Route exact path="/" component={LandingScreen} />
        <Route path="/terms" component={TermsAndConditions} />
        <Route path="/privacy" component={PrivacyPolicy} />
      </Switch>
    </div>
  );
}

export default App;
