import { Helmet } from "react-helmet";
import { Divider, Grid, Header, List, Segment } from "semantic-ui-react";

const scrollToSection = (sectionID) => () => {
  let element = document.getElementById(sectionID);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

function PrivacyPolicy() {
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Helmet>
        <title>Privacy Policy - RetroPortal Studio</title>
        <meta
          name="description"
          content="RetroPortal Studio (“we”,“us” or  “our”) respects the privacy of our users (“user”,“your” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.retroportalstudio.com or any of our provided Services, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Service”). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Service."
        />
      </Helmet>

      <Grid>
        <Grid.Column computer="6" only="computer" stretched>
          <Segment padded basic secondary>
            <List
              bulleted
              size="large"
              verticalAlign="middle"
              animated
              divided
              relaxed
              selection
            >
              <List.Item onClick={scrollToSection("introduction")}>
                <List.Content>Introduction</List.Content>
              </List.Item>
              <List.Item onClick={scrollToSection("information_collection")}>
                <List.Content>Collection of your Information:</List.Content>
                <List.List as="ul">
                  <List.Item onClick={scrollToSection("personal_data")} as="li">
                    <List.Content>Personal Data</List.Content>
                  </List.Item>
                  <List.Item
                    onClick={scrollToSection("derivative_data")}
                    as="li"
                  >
                    <List.Content>Derivative Data</List.Content>
                  </List.Item>
                  <List.Item
                    onClick={scrollToSection("financial_data")}
                    as="li"
                  >
                    <List.Content>Financial Data</List.Content>
                  </List.Item>

                  <List.Item onClick={scrollToSection("contests")} as="li">
                    <List.Content>
                      Data From Contests, Giveaways, and Surveys
                    </List.Content>
                  </List.Item>
                </List.List>
              </List.Item>
              <List.Item onClick={scrollToSection("information_use")}>
                <List.Content>Use of your Information</List.Content>
              </List.Item>
              <List.Item onClick={scrollToSection("information_disclosure")}>
                <List.Content>Disclosure of your Information</List.Content>
                <List.List as="ul">
                  <List.Content onClick={scrollToSection("for_law")} as="li">
                    By Law or to Protect Rights
                  </List.Content>
                  <List.Content
                    onClick={scrollToSection("third_party")}
                    as="li"
                  >
                    Derivative Data
                  </List.Content>
                  <List.Content onClick={scrollToSection("marketing")} as="li">
                    Marketing Communications
                  </List.Content>
                  <List.Content
                    onClick={scrollToSection("user_interaction")}
                    as="li"
                  >
                    Interactions with Other Users
                  </List.Content>
                  <List.Content
                    onClick={scrollToSection("online_postings")}
                    as="li"
                  >
                    Online Postings
                  </List.Content>
                  <List.Content
                    onClick={scrollToSection("third_party_adverts")}
                    as="li"
                  >
                    Third-Party Advertisers
                  </List.Content>
                  <List.Content onClick={scrollToSection("affiliates")} as="li">
                    Affiliates
                  </List.Content>
                  <List.Content
                    onClick={scrollToSection("business_partners")}
                    as="li"
                  >
                    Business Partners
                  </List.Content>
                  <List.Content
                    onClick={scrollToSection("other_third_parties")}
                    as="li"
                  >
                    Other Third Parties
                  </List.Content>
                  <List.Content
                    onClick={scrollToSection("sale_bankrupcy")}
                    as="li"
                  >
                    Sale or Bankruptcy
                  </List.Content>
                </List.List>
              </List.Item>
              <List.Item onClick={scrollToSection("analytics")}>
                <List.Content>Analytics</List.Content>
              </List.Item>
              <List.Item onClick={scrollToSection("third_party_websites")}>
                <List.Content>Third-Party Websites</List.Content>
              </List.Item>
              <List.Item onClick={scrollToSection("children_policy")}>
                <List.Content>Policy for Children</List.Content>
              </List.Item>
              <List.Item onClick={scrollToSection("contact_us")}>
                <List.Content>Contact Us</List.Content>
              </List.Item>
            </List>
          </Segment>
        </Grid.Column>
        <Grid.Column computer="10" mobile="16">
          <Segment
            padded
            id="terms_holder"
            basic
            style={{ overflowY: "scroll", height: "100vh" }}
          >
            <Header id="introduction" size="huge">
              Privacy Policy
            </Header>
            <Divider />
            <div className="legal">
              <strong>Last Updated: 10 October 2021</strong>
              <br />
              <br />
              <p>
                RetroPortal Studio (<strong>“we”</strong> or{" "}
                <strong>“us”</strong> or <strong>“our”</strong>) respects the
                privacy of our users (<strong>“user”</strong>,
                <strong>” your”</strong> or <strong>“you”</strong>). This
                Privacy Policy explains how we collect, use, disclose, and
                safeguard your information when you visit our website
                www.retroportalstudio.com or any of our provided Services,
                including any other media form, media channel, mobile website,
                or mobile application related or connected thereto
                (collectively, the <strong>“Service”</strong>). Please read this
                privacy policy carefully. If you do not agree with the terms of
                this privacy policy, please do not access the Service.
              </p>
              <p>
                We reserve the right to make changes to this Privacy Policy at
                any time and for any reason. We will alert you about any changes
                by updating the “Last Updated” date of this Privacy Policy. Any
                changes or modifications will be effective immediately upon
                posting the updated Privacy Policy on the Service, and you waive
                the right to receive specific notice of each such change or
                modification.
              </p>
              <p>
                You are encouraged to periodically review this Privacy Policy to
                stay informed of updates. You will be deemed to have been made
                aware of, will be subject to, and will be deemed to have
                accepted the changes in any revised Privacy Policy by your
                continued use of the Service after the date such revised Privacy
                Policy is posted.
              </p>
            </div>
            <Header id="information_collection" as="h2">
              Collection of your Information:
            </Header>
            <div className="legal">
              <p>
                We may collect information about you in a variety of ways. The
                information we may collect on the Service includes:
              </p>
              <Header id="personal_data" as="h4">
                Personal Data
              </Header>
              <p>
                Personally identifiable information, such as your name, shipping
                address, email address, and telephone number, and demographic
                information, such as your age, gender, hometown, and interests,
                that you voluntarily give to us when you register with the
                Service or when you choose to participate in various activities
                related to the Service such as online chat and message boards.
                You are under no obligation to provide us with personal
                information of any kind, however, your refusal to do so may
                prevent you from using certain features of the Service.
              </p>
              <Header id="derivative_data" as="h4">
                Derivative Data
              </Header>
              <p>
                The information our servers automatically collect when you
                access the Service, such as your IP address, your browser type,
                your operating system, your access times, and the pages you have
                viewed directly before and after accessing the Service.
              </p>
              <Header id="financial_data" as="h4">
                Financial Data
              </Header>
              <p>
                Financial information, such as data related to your payment
                method (e.g. valid credit card number, card brand, expiration
                date) that we may collect when you purchase, or order from the
                Service. We store only very limited, if any, financial
                information that we collect. Otherwise, all financial
                information is stored by our payment processor Stripe, and you
                are encouraged to review their privacy policy and contact them
                directly for responses to your questions.
              </p>
              <Header id="contests" as="h4">
                Data From Contests, Giveaways, and Surveys{" "}
              </Header>
              <p>
                Personal and other information you may provide when entering
                contests or giveaways and/or responding to surveys.
              </p>
            </div>
            <Header id="information_use" as="h2">
              Use of your Information:
            </Header>
            <div className="legal">
              <p>
                Having accurate information about you permits us to provide you
                with a smooth, efficient, and customized experience.
                Specifically, we may use information collected about you via the
                Service to:
              </p>
              <ul>
                <li>Administer promotions and contests.</li>
                <li>Assist law enforcement and respond to subpoena.</li>
                <li>
                  Compile anonymous statistical data and analysis for use
                  internally or with third parties.
                </li>
                <li>Create and manage your account.</li>
                <li>
                  Deliver targeted advertising, coupons, newsletters, and other
                  information regarding promotions and the Service to you.
                </li>
                <li>Email me regarding your account or order.</li>
                <li>Enable user-to-user communications.</li>
                <li>
                  Fulfill and manage purchases, orders, payments, and other
                  transactions related to the Service.
                </li>
                <li>
                  Generate a personal profile about you to make future visits to
                  the Service more personalized.
                </li>
                <li>Increase the efficiency and operation of the Service.</li>
                <li>
                  Monitor and analyze usage and trends to improve your
                  experience with the Service.
                </li>
                <li>Notify you of updates to the Service.</li>
                <li>
                  Offer new products, services, and/or recommendations to you.
                </li>
                <li>Perform other business activities as needed.</li>
                <li>
                  Prevent fraudulent transactions, monitor against theft, and
                  protect against criminal activity.
                </li>
                <li>Process payments and refunds.</li>
                <li>
                  Request feedback and contact you about your use of the
                  Service.
                </li>
                <li>Resolve disputes and troubleshoot problems.</li>
                <li>Respond to product and customer service requests.</li>
                <li>Send you a newsletter.</li>
                <li>Solicit support for the Service.</li>
              </ul>
            </div>
            <Header id="information_disclosure" as="h2">
              Disclosure of your Information:
            </Header>
            <div className="legal">
              <p>
                We may share information we have collected about you in certain
                situations. Your information may be disclosed as follows:
              </p>
              <Header id="for_law" as="h4">
                By Law or to Protect Rights
              </Header>
              <p>
                If we believe the release of information about you is necessary
                to respond to legal process, to investigate or remedy potential
                violations of our policies, or to protect the rights, property,
                and safety of others, we may share your information as permitted
                or required by any applicable law, rule, or regulation. This
                includes exchanging information with other entities for fraud
                protection and credit risk reduction.
              </p>
              <Header id="third_party" as="h4">
                Third-Party Service Providers
              </Header>
              <p>
                We may share your information with third parties that perform
                services for us or on our behalf, including payment processing,
                data analysis, email delivery, hosting services, customer
                service, and marketing assistance.
              </p>
              <Header id="marketing" as="h4">
                Marketing Communications
              </Header>
              <p>
                With your consent, or with an opportunity for you to withdraw
                consent, we may share your information with third parties for
                marketing purposes, as permitted by law.
              </p>
              <Header id="user_interaction" as="h4">
                Interactions with Other Users
              </Header>
              <p>
                If you interact with other users of the Service, those users may
                see your name, profile photo, and descriptions of your activity,
                including sending invitations to other users, chatting with
                other users, liking posts, following blogs.
              </p>
              <Header id="online_postings" as="h4">
                Online Postings
              </Header>
              <p>
                When you post comments, contributions, or other content to the
                Service, your posts may be viewed by all users and may be
                publicly distributed outside the Service in perpetuity.
              </p>
              <Header id="third_party_adverts" as="h4">
                Third-Party Advertisers
              </Header>
              <p>
                We may use third-party advertising companies to serve ads when
                you visit the Service. These companies may use information about
                your visits to the Service and other websites that are contained
                in web cookies in order to provide advertisements about goods
                and services of interest to you.
              </p>
              <Header id="affiliates" as="h4">
                Affiliates
              </Header>
              <p>
                We may share your information with our affiliates, in which case
                we will require those affiliates to honor this Privacy Policy.
                Affiliates include our parent company and any subsidiaries,
                joint venture partners, or other companies that we control or
                that are under common control with us.
              </p>
              <Header id="business_partners" as="h4">
                Business Partners
              </Header>
              <p>
                We may share your information with our business partners to
                offer you certain products, services, or promotions.
              </p>
              <Header id="other_third_parties" as="h4">
                Other Third Parties
              </Header>
              <p>
                We may share your information with advertisers and investors for
                the purpose of conducting general business analysis. We may also
                share your information with such third parties for marketing
                purposes, as permitted by law.
              </p>
              <Header id="sale_bankrupcy" as="h4">
                Sale or Bankruptcy
              </Header>
              <p>
                If we reorganize or sell all or a portion of our assets, undergo
                a merger, or are acquired by another entity, we may transfer
                your information to the successor entity. If we go out of
                business or enter bankruptcy, your information would be an asset
                transferred or acquired by a third party. You acknowledge that
                such transfers may occur and that the transferee may decline to
                honor commitments we made in this Privacy Policy.
              </p>
              <p>
                We are not responsible for the actions of third parties with
                whom you share personal or sensitive data, and we have no
                authority to manage or control third-party solicitations. If you
                no longer wish to receive correspondence, emails, or other
                communications from third parties, you are responsible for
                contacting the third party directly.
              </p>
            </div>
            <Header id="analytics" as="h2">
              Analytics:
            </Header>
            <div className="legal">
              <p>
                We may also partner with selected third-party vendors such as
                Google Analytics, to allow tracking technologies and remarketing
                services on the Service through the use of first-party cookies
                and third-party cookies, to, among other things, analyze and
                track users’ use of the Service, determine the popularity of
                certain content and better understand online activity. By
                accessing the Service, you consent to the collection and use of
                your information by these third-party vendors. You are
                encouraged to review their privacy policy and contact them
                directly for responses to your questions. We do not transfer
                personal information to these third-party vendors.
              </p>
            </div>
            <Header id="third_party_websites" as="h2">
              Third-Party Websites:
            </Header>
            <div className="legal">
              <p>
                The Service may contain links to third-party websites and
                applications of interest, including advertisements and external
                services, that are not affiliated with us. Once you have used
                these links to leave the Service, any information you provide to
                these third parties is not covered by this Privacy Policy, and
                we cannot guarantee the safety and privacy of your information.
                Before visiting and providing any information to any third-party
                websites, you should inform yourself of the privacy policies and
                practices (if any) of the third-party responsible for that
                website, and should take those steps necessary to, in your
                discretion, protect the privacy of your information. We are not
                responsible for the content or privacy and security practices
                and policies of any third parties, including other sites,
                services, or applications that may be linked to or from the
                Service.
              </p>
            </div>
            <Header id="children_policy" as="h2">
              Policy For Children:
            </Header>
            <div className="legal">
              <p>
                We do not knowingly solicit information from or market to
                children under the age of 13. If you become aware of any data we
                have collected from children under age 13, please contact us
                using the contact information provided below.
              </p>
            </div>
            <Header id="contact_us" as="h2">
              Contact Us:
            </Header>
            <div className="legal">
              <p>
                In order to resolve a complaint regarding this Privacy Policy or
                to receive further information regarding Privacy Policy, please
                contact us at{" "}
                <a href="mailto:retroportalstudio@gmail.com">
                  retroportalstudio@gmail.com
                </a>
              </p>
            </div>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default PrivacyPolicy;
