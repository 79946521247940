import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Divider, Grid, Header, Segment } from "semantic-ui-react";

export default class LandingScreen extends Component {
  render() {
    return (
      <div style={{ height: "100%" }}>
        <Helmet>
          <title>RetroPortal Studio</title>
          <meta
            name="description"
            content="Exclusive Flutter Tutorials to take your Flutter Development Skills to the Next Level!"
          />
        </Helmet>
        <Segment inverted basic style={{ height: "100%" }}>
          <Grid stretched style={{ height: "100%" }}>
            <Grid.Column verticalAlign="middle">
              <Header inverted textAlign="center" style={{ fontSize: "4em" }}>
                Getting a Facelift!
                <Divider />
                <Header.Subheader style={{ fontSize: "0.7em" }}>
                  We'll be Back Soon!
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}
