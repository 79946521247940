import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Header, Image, Menu } from "semantic-ui-react";

export default class Navbar extends Component {
  render() {
    return (
      <div>
        <Menu inverted className="no-border" borderless>
          <Menu.Item as={Link} to="/">
            <Image src="assets/logo.png" size="mini" />
          </Menu.Item>
          <Menu.Item as={Link} to="/">
            <Header size="large" inverted>
              RetroPortal Studio
            </Header>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item as={Link} to="/terms">
              {" "}
              Terms
            </Menu.Item>
            <Menu.Item as={Link} to="/privacy">
              {" "}
              Privacy
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}
