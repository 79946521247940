import { Helmet } from "react-helmet";
import { Divider, Grid, Header, List, Segment } from "semantic-ui-react";

const scrollToSection = (sectionID) => () => {
  let element = document.getElementById(sectionID);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

function TermsAndConditions() {
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Helmet>
        <title>Terms and Conditions - RetroPortal Studio</title>
        <meta
          name="description"
          content="These Terms and Conditions constitute a legally binding agreement made between you, whether
                personally or on behalf of an entity you represent, as applicable (the“User”, “you”,and “your”), and RetroPortal Studio (“we”, “us” or “our”>), concerning your access to and use of the internet-based applications, software, services and web services offered by RetroPortal Studio."
        />
      </Helmet>
      <Grid>
        <Grid.Column computer="6" only="computer" stretched>
          <Segment padded basic secondary>
            <List
              bulleted
              size="large"
              verticalAlign="middle"
              animated
              divided
              relaxed
              selection
            >
              <List.Item onClick={scrollToSection("introduction")}>
                <List.Content>Introduction</List.Content>
              </List.Item>
              <List.Item onClick={scrollToSection("account_registration")}>
                <List.Content>Account Registration</List.Content>
              </List.Item>
              <List.Item onClick={scrollToSection("account_security")}>
                <List.Content>Account Security</List.Content>
              </List.Item>
              <List.Item onClick={scrollToSection("account_termination")}>
                Account Termination
              </List.Item>
              <List.Item onClick={scrollToSection("subscription")}>
                Subscription
              </List.Item>
              <List.Item onClick={scrollToSection("subscription_cancellation")}>
                Subscription Cancellation
              </List.Item>
              <List.Item onClick={scrollToSection("user_generated_content")}>
                User Generated Content
              </List.Item>
              <List.Item onClick={scrollToSection("contribution_license")}>
                Contribution License
              </List.Item>
              <List.Item onClick={scrollToSection("copyright_infringements")}>
                Copyright Infringements
              </List.Item>
              <List.Item onClick={scrollToSection("third_party_content")}>
                Third-Party Web Services
              </List.Item>
              <List.Item onClick={scrollToSection("service_management")}>
                Service Management
              </List.Item>
              <List.Item onClick={scrollToSection("modifications")}>
                Modifications and Interruptions
              </List.Item>
              <List.Item onClick={scrollToSection("miscellaneous")}>
                Miscellaneous
              </List.Item>
              <List.Item onClick={scrollToSection("contact")}>
                Contact Us
              </List.Item>
            </List>
          </Segment>
        </Grid.Column>
        <Grid.Column computer="10" mobile="16">
          <Segment
            padded
            basic
            style={{ overflowY: "scroll", height: "100vh" }}
          >
            <Header id="introduction" size="huge">
              Terms and Conditions
            </Header>
            <Divider />
            <div className="legal">
              <strong>Last Updated: 10 October 2021</strong>
              <br />
              <br />
              <p>
                These Terms and Conditions(the <strong>“Terms”</strong>)
                constitute a legally binding agreement made between you, whether
                personally or on behalf of an entity you represent, as
                applicable (the <strong>“User”</strong>, <strong>“you”</strong>,
                and <strong>“your”</strong>), and RetroPortal Studio (“we”, “us”
                or <strong>“our”</strong>), concerning your access to and use of
                the internet-based applications, software, services and web
                services offered by RetroPortal Studio (the{" "}
                <strong>“Service”</strong>).
              </p>
              <p>
                By creating an account on the Service (an “Account”), or
                otherwise using the Service, you agree, as an individual or on
                behalf of the entity that you represent, to be bound by these
                Terms. If you do not agree with all of these Terms and
                Conditions, then you are expressly prohibited from using the
                Service and you must discontinue use immediately.
              </p>
              <p>
                We reserve the right, in our sole discretion, to make changes or
                modifications to these Terms and Conditions at any time and for
                any reason. We will alert you about any changes by updating the
                “Last Updated” date of these Terms and Conditions, and you waive
                any right to receive specific notice of each such change.
              </p>
              <p>
                It is your responsibility to periodically review these Terms and
                Conditions to stay informed of updates. You will be subject to
                and will be deemed to have been made aware of and to have
                accepted, the changes in any revised Terms and Conditions by
                your continued use of the Service after the date such revised
                Terms and Conditions are posted.
              </p>
              <p>
                The information provided on the Service is not intended for
                distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.
              </p>
              <p>
                Accordingly, those persons who choose to access the Service from
                other locations do so on their own initiative and are solely
                responsible for compliance with local laws, if and to the extent
                local laws are applicable.
              </p>
              <p>By using the Service, you represent and warrant that:</p>
              <ul>
                <li>
                  You have the legal capacity and you agree to comply with these
                  Terms and Conditions;
                </li>
                <li>
                  You are 13 years or older and are not barred from using the
                  Services under applicable law. If you are a minor, you must
                  have your parent or guardian read and agree to these Terms and
                  Conditions prior to you using the Service.
                </li>
                <li>
                  You will not access the Service through automated or non-human
                  means, whether through a bot, script, or otherwise.
                </li>
                <li>
                  You will not use the Service for any illegal or unauthorized
                  purpose.
                </li>
                <li>
                  You will not systematically retrieve data or other content
                  from the Service to create or compile, directly or indirectly,
                  a collection, compilation, database, or directory without
                  written permission from us.
                </li>
                <li>
                  You will not make any unauthorized use of the Service,
                  including collecting usernames and/or email addresses of users
                  by electronic or other means for the purpose of sending
                  unsolicited emails or creating user accounts by automated
                  means or under false pretenses.
                </li>
                <li>
                  You will not use the Service to advertise or offer to sell
                  goods and services.
                </li>
                <li>
                  You will not circumvent, disable, or otherwise interfere with
                  security-related features of the Service, including features
                  that prevent or restrict the use or copying of any Content or
                  enforce limitations on the use of the Service and/or the
                  Content contained therein.
                </li>
                <li>
                  You will not engage in unauthorized framing of or linking to
                  the Service.
                </li>
                <li>
                  You will not trick, defraud, or mislead us and other users,
                  especially in any attempt to learn sensitive account
                  information such as user passwords;
                </li>
                <li>
                  You will not make improper use of our support services or
                  submit false reports of abuse or misconduct.
                </li>
                <li>
                  You will not engage in any automated use of the system, such
                  as using scripts to send comments or messages or using any
                  data mining, robots, or similar data gathering and extraction
                  tools.
                </li>
                <li>
                  You will not interfere with, disrupt, or create an undue
                  burden on the Service or the networks or services connected to
                  the Service.
                </li>
                <li>
                  You will not attempt to impersonate another user or person or
                  use the email address of another user.
                </li>
                <li>You will not sell or otherwise transfer your profile.</li>
                <li>
                  You will not use any information obtained from the Service in
                  order to harass, abuse, or harm another person.
                </li>
                <li>
                  You will not use the Service as part of any effort to compete
                  with us or otherwise use the Service and/or the Content for
                  any revenue-generating endeavor or commercial enterprise.
                </li>
                <li>
                  You will not decipher, decompile, disassemble, or reverse
                  engineer any of the software comprising or in any way making
                  up a part of the Service.
                </li>
                <li>
                  You will not attempt to bypass any measures of the Service
                  designed to prevent or restrict access to the Service, or any
                  portion of the Service.
                </li>
                <li>
                  You will not harass, annoy, intimidate, or threaten any of our
                  employees or agents engaged in providing any portion of the
                  Service to you.
                </li>
                <li>
                  You will not delete the copyright or other proprietary rights
                  notice from any Content.
                </li>
                <li>
                  You will not copy or adapt the Service’s software, including
                  but not limited to HTML, JavaScript, Dart, Flutter, or other
                  code.
                </li>
                <li>
                  You will not upload or transmit (or attempt to upload or to
                  transmit) viruses, Trojan horses, or other material, including
                  excessive use of capital letters and spamming (continuous
                  posting of repetitive text), that interferes with any party’s
                  uninterrupted use and enjoyment of the Service or modifies,
                  impairs, disrupts, alters, or interferes with the use,
                  features, functions, operation, or maintenance of the Service.
                </li>
                <li>
                  You will not upload or transmit (or attempt to upload or to
                  transmit) any material that acts as a passive or active
                  information collection or transmission mechanism, including
                  without limitation, clear graphics interchange formats
                  (“gifs”), 1×1 pixels, web bugs, cookies, or other similar
                  devices (sometimes referred to as “spyware” or “passive
                  collection mechanisms” or “pcms”).
                </li>
                <li>
                  You will not disparage, tarnish, or otherwise harm, in our
                  opinion, us and/or the Service.
                </li>
                <li>
                  You will not use the Service in a manner inconsistent with any
                  applicable laws or regulations
                </li>
              </ul>
            </div>
            <Header id="account_registration" as="h3">
              Account Registration:
            </Header>
            <div className="legal">
              <p>
                You may access certain features of the Service without creating
                an Account or by Logging In. But in order to use Service with
                all its features, you must create an Account and become a
                Registered User. In order to create an Account and become a
                Registered User of our Service, you must provide us with
                complete and accurate information and keep it up-to-date,
                including a valid email address and, if you purchase a
                Subscription, all required billing information.
              </p>
              <p>
                If you create an account on behalf of an entity, you represent
                and warrant that you have the authority to enter into these
                Terms on behalf of such entity. If you create an Account on
                behalf of an entity but do not have the authority to bind the
                entity to these Terms, then you as an individual are the User.
              </p>
              <p>
                If you provide any information that is untrue, inaccurate, not
                current, or incomplete, we have the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Service (or any portion thereof).
              </p>
              <p>
                We reserve the right to remove your Account, if we determine, at
                our sole discretion, that any details provided by you are
                inappropriate, obscene, or otherwise objectionable.
              </p>
            </div>
            <Header id="account_security" as="h3">
              Account Security:
            </Header>
            <div className="legal">
              <p>
                You are responsible for maintaining the confidentiality of your
                Account credentials and must notify us immediately if you become
                aware of any unauthorized access or use of your Account. You are
                responsible for all activity that occurs under your Account.
              </p>
              <p>
                By agreeing to these Terms and Conditions, You confirm your
                consent that you, as an owner of the access email and password,
                bear responsibility for each access to the Service using the
                chosen email and password. Also, You are informed and agree that
                We automatically accept and recognize your email and password as
                evidence and clear proof of the fact that it was you who gained
                access to the Service content. You realize that the email and
                password are unique symbols, even though We do not impose
                specific requirements as to their contents and quality.
              </p>
            </div>
            <Header id="account_termination" as="h3">
              Account Termination:
            </Header>
            <div className="legal">
              <p>
                Upon termination of the Account and your access to the Service
                files, elements, and designs, you confirm your consent to
                forfeit all remaining Credits Associated with the Service. If we
                terminate or suspend your account for any reason, you are
                prohibited from registering and creating a new account under
                your name, a fake or borrowed name, or the name of any third
                party, even if you may be acting on behalf of the third party.
                In addition to terminating or suspending your account, we
                reserve the right to take appropriate legal action, including
                without limitation pursuing civil, criminal, and injunctive
                redress.
              </p>
            </div>
            <Header id="subscription" as="h3">
              Subscriptions:
            </Header>
            <div className="legal">
              <p>
                Your rights and the features of the Service available to you may
                depend upon the level of Subscription purchased. When you
                purchase a Subscription, you may select to be billed monthly or
                annually, and you will be billed immediately for the applicable
                interval.
              </p>
              <p>
                We may collect Financial information, such as data related to
                your payment method (e.g. valid credit card number, card brand,
                expiration date) when you purchase, or order from the Service.
                We store only very limited, if any, financial information that
                we collect. Otherwise, all financial information is stored by
                our payment processor Stripe, and you are encouraged to review
                their Terms and Conditions or Privacy Policy and contact them
                directly for responses to your questions.
              </p>
              <p>
                You may request to change the type or status of Subscription at
                any time by emailing us at fluttershapemaker@gmail.com and
                following any instructions, if any, we provide to you in
                response to your change request.
              </p>
            </div>
            <Header id="subscription_cancellation" as="h3">
              Subscription Cancellation:
            </Header>
            <div className="legal">
              <p>
                You may request to cancel your subscription to the Service,
                effective upon the end of the then-current billing period,
                provided that you will not be entitled to receive any refund of
                any fees in connection with a cancellation. You can request a
                cancellation by emailing us at retroportalstudio@gmail.com.
              </p>
            </div>
            <Header id="user_generated_content" as="h3">
              User-Generated Content:
            </Header>
            <div className="legal">
              <p>
                You may create, submit, post, display, transmit, perform,
                publish, distribute, or broadcast content and materials to us or
                on the Service, including but not limited to text, writings,
                video, audio, photographs, graphics, comments, suggestions, or
                personal information or other material (collectively,
                "Contributions").
              </p>
              <p>
                Contributions may be viewable by other users of the Service and
                through third-party web Services. As such, any Contributions you
                transmit may be treated as non-confidential and non-proprietary.
                When you create or make available any Contributions, you thereby
                represent and warrant that:
              </p>
              <ol>
                <li>
                  the creation, distribution, transmission, public display, or
                  performance, and the accessing, downloading, or copying of
                  your Contributions do not and will not infringe the
                  proprietary rights, including but not limited to the
                  copyright, patent, trademark, trade secret, or moral rights of
                  any third party.
                </li>
                <li>
                  you are the creator and owner of or have the necessary
                  licenses, rights, consents, releases, and permissions to use
                  and to authorize us, the Service, and other users of the
                  Service to use your Contributions in any manner contemplated
                  by the Service and these Terms and Conditions.
                </li>
                <li>
                  you have the written consent, release, and/or permission of
                  each and every identifiable individual person in your
                  Contributions to use the name or likeness of each and every
                  such identifiable individual person to enable inclusion and
                  use of your Contributions in any manner contemplated by the
                  Service and these Terms and Conditions.
                </li>
                <li>
                  your Contributions are not false, inaccurate, or misleading.
                </li>
                <li>
                  your Contributions are not unsolicited or unauthorized
                  advertising, promotional materials, pyramid schemes, chain
                  letters, spam, mass mailings, or other forms of solicitation.
                </li>
                <li>
                  your Contributions are not obscene, lewd, lascivious, filthy,
                  violent, harassing, libelous, slanderous, or otherwise
                  objectionable (as determined by us).
                </li>
                <li>
                  your Contributions do not ridicule, mock, disparage,
                  intimidate, or abuse anyone.
                </li>
                <li>
                  your Contributions do not advocate the violent overthrow of
                  any government or incite, encourage, or threaten physical harm
                  against another.
                </li>
                <li>
                  your Contributions do not violate any applicable law,
                  regulation, or rule.
                </li>
                <li>
                  your Contributions do not violate the privacy or publicity
                  rights of any third party.
                </li>
                <li>
                  your Contributions do not contain any material that solicits
                  personal information from anyone under the age of 18 or
                  exploits people under the age of 18 in a sexual or violent
                  manner.
                </li>
                <li>
                  your Contributions do not violate any federal or state law
                  concerning child pornography, or otherwise intended to protect
                  the health or well-being of minors;
                </li>
                <li>
                  your Contributions do not include any offensive comments that
                  are connected to race, national origin, gender, sexual
                  preference, or physical handicap.
                </li>
                <li>
                  your Contributions do not otherwise violate or link to
                  material that violates, any provision of these Terms and
                  Conditions, or any applicable law or regulation.
                </li>
              </ol>
              <p>
                Any use of the Service in violation of the foregoing violates
                these Terms and Conditions and may result in, among other
                things, termination or suspension of your account and rights to
                use the Service.
              </p>
            </div>
            <Header id="contribution_license" as="h3">
              Contribution License:
            </Header>
            <div className="legal">
              <p>
                By posting your Contributions to any part of the Service [or
                making Contributions accessible to the Service by linking your
                account from the Service to any of your social networking
                accounts], you automatically grant, and you represent and
                warrant that you have the right to grant, to us an unrestricted,
                unlimited, irrevocable, perpetual, non-exclusive, transferable,
                royalty-free, fully-paid, worldwide right, and license to host,
                use, copy, reproduce, disclose, sell, resell, publish,
                broadcast, retitle, archive, store, cache, publicly perform,
                publicly display, reformat, translate, transmit, excerpt (in
                whole or in part), and distribute such Contributions (including,
                without limitation, your image and voice) for any purpose,
                commercial, advertising, or otherwise, and to prepare derivative
                works of, or incorporate into other works, such Contributions,
                and grant and authorize sublicenses of the foregoing. The use
                and distribution may occur in any media format and through any
                media channel.
              </p>
              <p>
                This license will apply to any form, media, or technology now
                known or hereafter developed, and includes our use of your name,
                company name, and franchise name, as applicable, and any of the
                trademarks, service marks, trade names, logos, and personal and
                commercial images you provide. You waive all moral rights in
                your Contributions, and you warrant that moral rights have not
                otherwise been asserted in your Contributions.
              </p>
              <p>
                We do not assert any ownership over your Contributions. You
                retain full ownership of all of your Contributions and any
                intellectual property rights or other proprietary rights
                associated with your Contributions. We are not liable for any
                statements or representations in your Contributions provided by
                you in any area of the Service.
              </p>
              <p>
                You are solely responsible for your Contributions to the Service
                and you expressly agree to exonerate us from any and all
                responsibility and to refrain from any legal action against us
                regarding your Contributions.
              </p>
              <p>
                We have the right, in our sole and absolute discretion, (1) to
                edit, redact, or otherwise change any Contributions; (2) to
                re-categorize any Contributions to place them in more
                appropriate locations on the Service; and (3) to pre-screen or
                delete any Contributions at any time and for any reason, without
                notice. We have no obligation to monitor your Contributions.
              </p>
              <p>
                We will maintain certain data that you transmit to the Service
                for the purpose of managing the Service, as well as data
                relating to your use of the Service. Although we perform regular
                routine backups of data, you are solely responsible for all data
                that you transmit or that relates to any activity you have
                undertaken using the Service.
              </p>
              <p>
                You agree that we shall have no liability to you for any loss or
                corruption of any such data, and you hereby waive any right of
                action against us arising from any such loss or corruption of
                such data.
              </p>
            </div>
            <Header id="copyright_infringements" as="h3">
              Copyright Infringements:
            </Header>
            <div className="legal">
              <p>
                If you have grounds to believe that your work has been copied in
                violation of copyright, or your other intellectual property
                rights have been violated directly or indirectly, you should
                provide us with a claim or notice of such infringement in
                accordance with the contact information given within the Terms
                and Conditions ​​(a <strong>“Notification”</strong>).
              </p>
              <p>
                Please be advised that pursuant to federal law you may be held
                liable for damages if you make material misrepresentations in a
                Notification. Thus, if you are not sure that material located on
                or linked to by the Site infringes your copyright, you should
                consider first contacting an attorney.]
              </p>
            </div>
            <Header id="third_party_content" as="h3">
              Third-Party Web Services and Content:
            </Header>
            <div className="legal">
              <p>
                The Service may contain (or you may be sent via the Service)
                links to other web services ("Third-Party Web Services") as well
                as articles, photographs, text, graphics, pictures, designs,
                music, sound, video, information, applications, software, and
                other content or items belonging to or originating from third
                parties ("Third-Party Content").
              </p>
              <p>
                Such Third-Party Web Services and Third-Party Content are not
                investigated, monitored, or checked for accuracy,
                appropriateness, or completeness by us, and we are not
                responsible for any Third-Party WebServices accessed through the
                Service or any Third-Party Content posted on, available through,
                or installed from the Service, including the content, accuracy,
                offensiveness, opinions, reliability, privacy practices, or
                other policies of or contained in the Third-Party Web Services
                or the Third-Party Content.
              </p>
              <p>
                Inclusion of, linking to, or permitting the use or installation
                of any Third-Party Web Services or any Third-Party Content does
                not imply approval or endorsement thereof by us. If you decide
                to leave the Service and access the Third-Party Web Services or
                to use or install any Third-Party Content, you do so at your own
                risk, and you should be aware these Terms and Conditions no
                longer govern.
              </p>
              <p>
                You should review the applicable terms and policies, including
                privacy and data gathering practices, of any web service to
                which you navigate from the Service or relating to any
                applications you use or install from the Service. Any purchases
                you make through Third-Party Web Services will be through other
                web services and from other companies, and we take no
                responsibility whatsoever in relation to such purchases which
                are exclusively between you and the applicable third party.
              </p>
              <p>
                You agree and acknowledge that we do not endorse the products or
                services offered on Third-Party Web Services and you shall hold
                us harmless from any harm caused by your purchase of such
                products or services. Additionally, you shall hold us harmless
                from any losses sustained by you or harm caused to you relating
                to or resulting in any way from any Third-Party Content or any
                contact with Third-Party Web Services.
              </p>
            </div>
            <Header id="service_management" as="h3">
              Service Management and Corrections:
            </Header>
            <div className="legal">
              <p>
                There may be information on the Service that contains
                typographical errors, inaccuracies, or omissions that may relate
                to the Service, including descriptions, pricing, availability,
                and various other information. We reserve the right to correct
                any errors, inaccuracies, or omissions and to change or update
                the information on the Service at any time, without prior
                notice.
              </p>
              <p>
                We update technical, content, and other information on the
                Service on regular basis. We make no claim guaranteeing the
                completeness, accuracy, and compliance of the information given
                on the Service. We are able to add, review or delete
                information, resources, and functionalities provided on the
                Service. Also, We reserve the right to make changes to the
                Service without prior notification.
              </p>
              <p>
                By agreeing to these Terms of Conditions, you acknowledge your
                consent to receive electronic newsletters and notifications from
                us.
              </p>
            </div>
            <Header id="modifications" as="h3">
              Modifications and Interruptions:
            </Header>
            <div className="legal">
              <p>
                We reserve the right to change, modify, or remove the contents
                of the Service at any time or for any reason at our sole
                discretion without notice. However, we have no obligation to
                update any information on our Service. We also reserve the right
                to modify or discontinue all or part of the Service without
                notice at any time. We will not be liable to you or any third
                party for any modification, price change, suspension, or
                discontinuance of the Service.
              </p>
              <p>
                We cannot guarantee the Service will be available at all times.
                We may experience hardware, software, or other problems or need
                to perform maintenance related to the Service, resulting in
                interruptions, delays, or errors.
              </p>
              <p>
                We reserve the right to change, revise, update, suspend,
                discontinue, or otherwise modify the Service at any time or for
                any reason without notice to you. You agree that we have no
                liability whatsoever for any loss, damage, or inconvenience
                caused by your inability to access or use the Service during any
                downtime or discontinuance of the Service.
              </p>
              <p>
                Nothing in these Terms and Conditions will be construed to
                obligate us to maintain and support the Service or to supply any
                corrections, updates, or releases in connection therewith.
              </p>
            </div>
            <Header id="miscellaneous" as="h3">
              Miscellaneous:
            </Header>
            <div className="legal">
              <p>
                These Terms and Conditions and any policies or operating rules
                posted by us on the Service constitute the entire agreement and
                understanding between you and us. Our failure to exercise or
                enforce any right or provision of these Terms and Conditions
                shall not operate as a waiver of such right or provision.
              </p>
              <p>
                These Terms and Conditions operate to the fullest extent
                permissible by law. We may assign any or all of our rights and
                obligations to others at any time. We shall not be responsible
                or liable for any loss, damage, delay, or failure to act caused
                by any cause beyond our reasonable control.
              </p>
              <p>
                If any provision or part of a provision of these Terms and
                Conditions is determined to be unlawful, void, or unenforceable,
                that provision or part of the provision is deemed severable from
                these Terms and Conditions and does not affect the validity and
                enforceability of any remaining provisions.
              </p>
              <p>
                There is no joint venture, partnership, employment, or agency
                relationship created between you and us as a result of these
                Terms and Conditions or use of the Service. You agree that these
                Terms and Conditions will not be construed against us by virtue
                of having drafted them.
              </p>
              <p>
                You hereby waive any and all defenses you may have based on the
                electronic form of these Terms and Conditions and the lack of
                signing by the parties hereto to execute these Terms and
                Conditions.
              </p>
            </div>
            <Header id="contact" as="h3">
              Contact Us:
            </Header>
            <div className="legal">
              <p>
                In order to resolve a complaint regarding the Service or to
                receive further information regarding the use of the Service,
                please contact us at{" "}
                <a href="mailto:retroportalstudio@gmail.com">
                  retroportalstudio@gmail.com
                </a>
              </p>
            </div>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default TermsAndConditions;
